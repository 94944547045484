import React from 'react';

import Layout from "../components/layout.js";
import Home from "../components/home.js";
import SEO from "../components/SEO.js";

function Index () {
    return (
        <Layout>
            <SEO title="Home" />
            <Home />
        </Layout>
    );
}

export default Index;