import React, {useState, useEffect} from "react";
import { Link } from "gatsby";

import KatDevsLogo from "../../static/images/KatDevsLogoOnly.png";
import BoralaaLogo from "../../static/images/boralaa_logo.png";
import QuemEhEste from "../../static/images/quem-eh-este.png";

function Home(props) {
    const [mainColor, setMainColor] = useState(props.mainColor);
    const [secondaryColor, setSecondaryColor] = useState(props.secondaryColor);

    useEffect(() => {
        setMainColor(props.mainColor);
        setSecondaryColor(props.secondaryColor);
    }, [props.mainColor, props.secondaryColor])

    return (
        <div>

            <div className="container">
                <div className="m-4 row justify-content-center">
                    <div className="col-12 col-sm-6">
                        <img src={KatDevsLogo} className="img-fluid" alt="Kat Devs Logo" />
                    </div>

                    <div id="div-nos-somos" className="col-12 col-sm-6 d-flex justify-content-center align-items-center">
                        <div className="mt-4 d-flex flex-column text-center text-sm-start">
                            <span>Nós somos a</span>
                            <h1 className="bungee-font" style={{fontSize: "8vh", lineHeight: "0.95"}}>Kat Devs</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{backgroundColor: secondaryColor}}>
                <div className="container">
                    <h2 className="pt-4 bungee-font text-center" style={{color: "white"}}>Nossos Projetos</h2>

                    <div className="p-4 row row-cols-1 row-cols-md-2 g-4">
                        <div className="col">
                            <div className="card h-100">
                                <img src={BoralaaLogo} className="card-img-top" alt="Boralaa Logo"></img>
                                <div className="card-body d-flex flex-column">
                                    <p className="card-text">
                                        Um <strong>Boss Rush</strong> de <strong>turno</strong> com elementos de <strong>Souls-like</strong>.
                                        Sem ajuda, apenas com sua atenção e estratégia!
                                        Jogue sozinho, com e contra seus amigos ou sua comunidade.
                                    </p>
                                    <div className="d-flex justify-content-end mt-auto">
                                        <Link to="/boralaa/br">
                                            <button className="btn btn-dark">Conheça</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100">
                                <img src={QuemEhEste} className="card-img-top" alt="Projeto Futuro"></img>
                                <div className="card-body d-flex flex-column">
                                    <p className="card-text">
                                        Num futuro próximo...
                                    </p>
                                    <div className="d-flex justify-content-end mt-auto">
                                        <Link to="/">
                                            <button className="btn btn-dark">???</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-4 row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">


                    </div>

                </div>
            </div>
            
        </div>
    )
}

export default Home;